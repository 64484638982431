import Swiper from "swiper";
import { Pagination, Autoplay } from "swiper/modules";

new Swiper(".swiper-card", {
  modules: [Pagination, Autoplay],
  grabCursor: true,
  speed: 600,
  pagination: {
    dynamicBullets: true,
    el: '.swiper-pagination',
    clickable: true
  },
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
});
