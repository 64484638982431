import Swiper from "swiper";
import { Autoplay, Navigation } from "swiper/modules";

new Swiper(".home-slider__swiper", {
  modules: [Navigation, Autoplay],
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  slidesPerView: 1,
  grabCursor: true,
  loop: true,
  breakpoints: {
    1024: {
      navigation: {
        prevEl: ".home-slider__prev",
        nextEl: ".home-slider__next",
      },
    },
  },
});
