const tabs = document.querySelectorAll(".tabs__btn");
const content = document.querySelectorAll(".tabs-content");
const events = document.querySelector(".events");
const marketing = document.querySelector(".marketing");
const pr = document.querySelector(".pr");
const education = document.querySelector(".education");
const digital = document.querySelector(".digital");
const consulting = document.querySelector(".consulting");

tabs.forEach((tab) => {
  tab.addEventListener("click", () => {
    tabs.forEach((btn) => {
      btn.classList.remove("tabs__btn-active");
    });

    content.forEach((box) => {
      box.classList.add("d-none");
    });

    tab.classList.add("tabs__btn-active");

    if (tab.getAttribute("data-id") === "1") {
      events.classList.remove("d-none");
    }
    if (tab.getAttribute("data-id") === "2") {
      marketing.classList.remove("d-none");
    }
    if (tab.getAttribute("data-id") === "3") {
      pr.classList.remove("d-none");
    }
    if (tab.getAttribute("data-id") === "4") {
      education.classList.remove("d-none");
    }
    if (tab.getAttribute("data-id") === "5") {
      digital.classList.remove("d-none");
    }
    if (tab.getAttribute("data-id") === "6") {
      consulting.classList.remove("d-none");
    }
  });
});
