import "swiper/css";
import "swiper/css/pagination";
import "./assets/styles/app.scss";

if (module.hot) {
  module.hot.accept();
}

$(function () {
  require("js/app");
});