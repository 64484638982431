import scrollLock from "scroll-lock";

const openBtn = document.querySelector(".open-popup");
const closeBtn = document.querySelector(".popup__btn");
const popup = document.querySelector(".popup");

if (popup) {
  if (document.documentElement.clientWidth < 768) {
    openBtn.addEventListener("click", () => {
      popup.classList.add("open");
      scrollLock.disablePageScroll();
    });

    closeBtn.addEventListener("click", () => {
      popup.classList.remove("open");
      scrollLock.enablePageScroll();
    });
  }
}
