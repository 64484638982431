const footer = document.querySelector(".footer__box");

footer.addEventListener("click", () => {
  footer.classList.toggle("footer__box-open");
  setTimeout(function() {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  }, 300);
});
