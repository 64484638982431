const openFormBtn = document.querySelector(".open-form");
const form = document.querySelector(".about-form");

if (openFormBtn) {
  if (document.documentElement.clientWidth >= 768) {
    openFormBtn.addEventListener("click", () => {
      form.classList.toggle("open");
      setTimeout(function () {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }, 300);
    });
  }
}
