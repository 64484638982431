customCursor();
function customCursor() {
  const cursor = document.querySelector(".cursor");

  document.addEventListener("mousemove", (e) => {
    const target = e.target;
    const x = e.clientX;
    const y = e.clientY;

    cursor.classList.remove("cursor-hide");

    cursor.style.left = x + "px";
    cursor.style.top = y + "px";

    if (target.tagName === "A" || target.tagName === "BUTTON") {
      cursor.classList.add("cursor-hover");
    } else {
      cursor.classList.remove("cursor-hover");
    }

    if (target.tagName === "VIDEO") {
      cursor.classList.add("cursor-watch");
    } else {
      cursor.classList.remove("cursor-watch");
    }

    if (
      target.classList.contains("footer__box-top") ||
      target.classList.contains("open-form") ||
      target.closest(".open-form") ||
      target.classList.contains("card-link") ||
      target.closest(".card-link") ||
      target.classList.contains("about__card-social") ||
      target.closest(".about__card-social")
    ) {
      cursor.classList.add("cursor-open");
    } else {
      cursor.classList.remove("cursor-open");
    }
  });

  document.addEventListener("mouseout", (e) => {
    cursor.classList.add("cursor-hide");
  });
}
