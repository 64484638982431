import Swiper from "swiper";
import { Autoplay } from "swiper/modules";

new Swiper(".clients__swiper", {
  modules: [Autoplay],
  speed: 3000,
  slidesPerView: 4,
  autoplay: {
    delay: 0,
    disableOnInteraction: false,
  },
  spaceBetween: 13,
  grabCursor: true,
  loop: true,
  breakpoints: {
    768: {
      slidesPerView: 4,
      spaceBetween: 40,
    },
    1024: {
      slidesPerView: 4,
      spaceBetween: 56,
    },
    1280: {
      slidesPerView: 5,
    },
  },
});
