import scrollLock from "scroll-lock";

const menuBtn = document.querySelector(".mobile__btn");
const menu = document.querySelector(".menu");
const projectsBtn = document.querySelector(".menu__link-projects");
const contactsBtn = document.querySelector(".menu__link-contacts");
const projectsBlock = document.querySelector(".menu__block-projects");
const contactsBlock = document.querySelector(".menu__block-contacts");

menuBtn.addEventListener("click", () => {
  menuBtn.classList.toggle("mobile__btn-open");
  menu.classList.toggle("menu-open");

  if (menuBtn.classList.contains("mobile__btn-open")) {
    scrollLock.disablePageScroll();
  } else {
    scrollLock.enablePageScroll();
  }

  if (!menu.classList.contains("menu-open")) {
    projectsBtn.classList.remove("menu__link-active");
    contactsBtn.classList.remove("menu__link-active");
    contactsBlock.classList.remove("menu__block-open");
    projectsBlock.classList.remove("menu__block-open");
  }
});

projectsBtn.addEventListener("click", () => {
  contactsBtn.classList.remove("menu__link-active");
  projectsBtn.classList.toggle("menu__link-active");

  projectsBlock.classList.toggle("menu__block-open");
  contactsBlock.classList.remove("menu__block-open");
});

contactsBtn.addEventListener("click", () => {
  projectsBtn.classList.remove("menu__link-active");
  contactsBtn.classList.toggle("menu__link-active");

  contactsBlock.classList.toggle("menu__block-open");
  projectsBlock.classList.remove("menu__block-open");
});
